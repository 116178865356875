export const signInUserData = [
    {
        id: '1',
        avatar: '/img/avatars/thumb-r.jpg',
        username: 'Rami Al Shaqsi',
        email: 'rami@midklick.com',
        authority: ['admin', 'user'],
        password: 'Rami9420!',
        accountusername: 'Rami',
    },
    {
        id: '2',
        avatar: '/img/avatars/thumb-2.jpg',
        username: 'Said Al Lamki',
        email: 'slamki@midklick.com',
        authority: ['clevel', 'user'],
        password: 'm!d22kl!ck',
        accountusername: 'Said',
    },
    {
        id: '3',
        avatar: '/img/avatars/thumb-4.jpg',
        username: 'Waleed Al Kharusi',
        email: 'waleed@midklick.com',
        authority: ['clevel', 'user'],
        password: 'm!d22kl!ck',
        accountusername: 'Waleed',
    },
    {
        id: '4',
        avatar: '/img/avatars/thumb-h.jpg',
        username: 'Haitham Al Shaqsi',
        email: 'haitham@midklick.com',
        authority: ['hod','user'],
        password: 'm!d22kl!ck',
        accountusername: 'Haitham',
    },
    {
        id: '5',
        avatar: '/img/avatars/thumb-a.jpg',
        username: 'Azza Al Shaqsi',
        email: 'azza@midklick.com',
        authority: ['hod','user'],
        password: 'm!d22kl!ck',
        accountusername: 'Azza',
    },
    {
        id: '6',
        avatar: '/img/avatars/thumb-4.jpg',
        username: 'UserSample',
        email: 'user@example.com',
        authority: 'user',
        password: 'm!d22kl!ck',
        accountusername: 'User',
    },
    {
        id: '7',
        avatar: '/img/avatars/thumb-7.jpg',
        username: 'ClientSample',
        email: 'client@example.com',
        authority: 'client',
        password: 'm!d22kl!ck',
        accountusername: 'Client',
    },
    
    {
        id: '8',
        avatar: '/img/avatars/thumb-7.jpg',
        username: 'birbaenergy',
        email: 'Ammar@birbaenergy.com',
        authority: 'client',
        password: '123456',
        accountusername: 'birbaenergy',
    },
]
