// ServiceWorkerCheck.js
import React, { useEffect, useState } from 'react';
import { Button, Dialog } from 'components/ui';

const ServiceWorkerCheck = () => {
    useEffect(() => {
        const registerServiceWorker = async () => {
            try {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker
                        .register('/serviceworker.js')
                        .then((registration) => {
                            console.log(
                                'Service Worker registered with scope:',
                                registration.scope
                            )
                            if ('Notification' in window) {
                                const askForNotificationPermission = () => {
                                    Notification.requestPermission().then(
                                        (permission) => {
                                            if (permission === 'granted') {
                                                // Permission has been granted, you can now show notifications
                                                console.log(
                                                    'Notification permission granted'
                                                )
                                            } else {
                                                console.warn(
                                                    'Notification permission denied'
                                                )
                                            }
                                        }
                                    )
                                }

                                const showCustomNotification = () => {
                                    // Trigger a custom notification
                                    registration.showNotification(
                                        'Hello Hello I 8989',
                                        {
                                            icon: '/logo192.png',
                                            badge: '/logo192.png',
                                        }
                                    )
                                }

                                // Expose the showCustomNotification function
                                ServiceWorkerCheck.showCustomNotification =
                                    showCustomNotification

                                // Attach the askForNotificationPermission function to a button click
                                const permissionButton =
                                    document.getElementById(
                                        'requestPermissionButton'
                                    )
                                if (permissionButton) {
                                    permissionButton.addEventListener(
                                        'click',
                                        askForNotificationPermission
                                    )
                                }

                                // Check if the notification permission is already granted or denied
                                if (Notification.permission === 'granted' || Notification.permission === 'denied') {
                                    setIsOpen(false); // Close the dialog
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(
                                'Service Worker registration failed:',
                                error
                            )
                        })
                } else {
                    console.warn(
                        'Service Worker is not supported in this browser.'
                    )
                }
            } catch (error) {
                console.error('Service Worker registration failed:', error)
            }
        }

        registerServiceWorker()
    }, [])
    const [dialogIsOpen, setIsOpen] = useState(true)

    const onDialogClose = (e) => {
        console.log('onDialogClose', e)
        setIsOpen(false)
    }

    // Using React here to resolve the 'React' is defined but never used warning
    return (
        <>
            {dialogIsOpen && (
                <Dialog
                    isOpen={dialogIsOpen}
                    onRequestClose={onDialogClose}
                >
                    <h4 className="mb-4">Dialog Title</h4>
                    <h6>
                        Enhance your experience. Enable notifications for timely updates and personalized content. Don't miss out!
                    </h6>
                    <div className="text-right mt-6">
                        <Button variant="solid" id="requestPermissionButton" onClick={onDialogClose}>
                            Allow
                        </Button>
                        <Button variant="plain" onClick={onDialogClose}>
                            Don't Allow
                        </Button>
                    </div>
                </Dialog>
            )}
        </>
    )
}

export default ServiceWorkerCheck;
