const appConfig = {
    // apiPrefix: 'http://localhost/api/src', //Testing
    apiPrefix: 'https://support.midklick.com/api/src', //Production
    authenticatedEntryPath: '/app/support/ticketing',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig
