import { createServer } from 'miragejs'
import appConfig from 'configs/app.config'
import { notificationListData, searchQueryPoolData } from './data/commonData'
import {
    projectList,
    scrumboardData,
    issueData,
    projectDashboardData,
} from './data/projectData'
import { usersData, userDetailData } from './data/usersData'
import { eventsData, mailData, crmDashboardData } from './data/crmData'
import {
    productsData,
    ordersData,
    orderDetailsData,
    salesDashboardData,
} from './data/salesData'
import {
    portfolioData,
    walletsData,
    marketData,
    transactionHistoryData,
    cryptoDashboardData,
} from './data/cryptoData'
import {
    settingData,
    settingIntergrationData,
    settingBillingData,
    invoiceData,
    logData,
    accountFormData,
} from './data/accountData'
import {
    helpCenterCategoriesData,
    helpCenterArticleListData,
} from './data/knowledgeBaseData'
import { signInUserData } from './data/authData'

// import {
//     commonFakeApi,
//     projectFakeApi,
//     crmFakeApi,
//     salesFakeApi,
//     accountFakeApi,
//     cryptoFakeApi,
//     authFakeApi,
//     knowledgeBaseFakeApi,
// } from './fakeApi'
import {
    commonApi,
    projectApi,
    crmApi,
    salesApi,
    accountApi,
    cryptoApi,
    authApi,
    knowledgeBaseApi,
} from './api'

const { apiPrefix } = appConfig

// export default function mockServer({ environment = 'test' }) {
export default function mockServer({ environment = 'production' }) {
    return createServer({
        environment,
        seeds(server) {
            server.db.loadData({
                notificationListData,
                searchQueryPoolData,
                projectList,
                scrumboardData,
                issueData,
                usersData,
                userDetailData,
                eventsData,
                mailData,
                productsData,
                ordersData,
                orderDetailsData,
                settingData,
                settingIntergrationData,
                settingBillingData,
                invoiceData,
                logData,
                accountFormData,
                portfolioData,
                walletsData,
                marketData,
                transactionHistoryData,
                helpCenterCategoriesData,
                helpCenterArticleListData,
                signInUserData,
                salesDashboardData,
                crmDashboardData,
                projectDashboardData,
                cryptoDashboardData,
            })
        },
        routes() {
            this.urlPrefix = 'localhost/api/src'
            this.namespace = ''
            this.passthrough((request) => {
                let isExternal = request.url.startsWith('http')
                return isExternal
            })
            this.passthrough()

            // commonFakeApi(this, apiPrefix)
            // projectFakeApi(this, apiPrefix)
            // crmFakeApi(this, apiPrefix)
            // salesFakeApi(this, apiPrefix)
            // accountFakeApi(this, apiPrefix)
            // authFakeApi(this, apiPrefix)
            // cryptoFakeApi(this, apiPrefix)
            // knowledgeBaseFakeApi(this, apiPrefix)

            commonApi(this, apiPrefix)
            projectApi(this, apiPrefix)
            crmApi(this, apiPrefix)
            salesApi(this, apiPrefix)
            accountApi(this, apiPrefix)
            authApi(this, apiPrefix)
            cryptoApi(this, apiPrefix)
            knowledgeBaseApi(this, apiPrefix)
        },
    })
}
